import React from 'react';
import { graphql } from 'gatsby';
import { Container, Grid, Layout, Section } from '../../components';
import { Hero } from '../../components/Hero/Hero';
import { PageData, SermonData } from '../../types';
import InnerHTML from 'dangerously-set-html-content';

interface DirectusSermonData {
  directus: {
    sermon: SermonData;
    page: PageData[];
  };
}

const Sermon: React.FC<{ data: DirectusSermonData }> = ({ data }) => {
  const sermon = data.directus.sermon;
  const heroData = data.directus.page?.length
    ? { ...data.directus.page[0], hero_type: 'standard', hero_title: sermon.hero_title }
    : {};

  return (
    <Layout pageData={{ title: sermon.title }} metadata={{ bodyClassName: `sermon sermon-${sermon.id}` }}>
      <Hero data={heroData as PageData} />
      <Section>
        <Container>
          <Grid>
            <div className="col-span-12 md:col-span-10 md:col-start-2">
              <InnerHTML html={sermon.buzzsprout_script} />
              <div
                className="prose lg:prose-xl max-w-none mt-6 sm:mt-8 md:mt-10"
                dangerouslySetInnerHTML={{ __html: sermon.content }}
              />
            </div>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($id: ID!) {
    directus {
      sermon: sermon_by_id(id: $id) {
        ...SermonFields
      }
      page(filter: { url: { _eq: "/sermons" } }) {
        hero_image {
          id
          description
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 50)
            }
          }
        }
      }
    }
  }
`;

export default Sermon;
